<template>
    <div class="wrap">
        <section class="introduction">
            <div class="content-container intro-container">
                <div class="intro">
                    <div class="name">福州永诚招标代理有限公司</div>
                    <p class="content">
                        福州永诚招标代理有限公司于2015年10月16日注册成立，注册资本501万元。<br>
                        我司从事招标代理服务，业务以政府采购代理为核心，向工程招标、项目管理等
                        方向延伸。<br>
                        服务领域涵盖各级政府部门、银行、医院、高等学校、央企和国有企业等社会各行
                        业，具有较强的专业化运营能力。  
                    </p>
                    <button class="blue-btn" @click="goIntro">查看更多</button>
                </div>
                <div class="keywords">
                    招标代理、政府采购咨询、<br>
                    建筑工程规划设计、工程监理、<br>
                    工程造价咨询、工程项目管理
                </div>
            </div>
        </section>
        <section class="bidding">
            <div class="content-container bidding-container">
                <div class="main-title">
                    <div class="title">
                        竞价中心
                        <span>竞价中心</span>
                    </div>
                </div>
                <!-- <el-table
                class="my-table"
                :data="biddingData"
                style="width: 100%">
                    <el-table-column
                    prop="position"
                    label="地区">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    label="项目名称"
                    width="400">
                    </el-table-column>
                    <el-table-column
                    prop="issueTime"
                    label="发布时间">
                    </el-table-column>
                    <el-table-column
                    prop="status"
                    label="报价情况">
                    </el-table-column>
                    <el-table-column
                    prop="endTime"
                    label="结束时间">
                    </el-table-column>
                    <el-table-column>
                        <button class="btn plain-btn">立即报价</button>
                    </el-table-column>
                </el-table> -->
                <div class="developing">
                    <img src="@/assets/image/default/developing.png">
                    正在加速开发中...
                </div>
            </div>
        </section>
        <section class="information">
            <div class="content-container information-container">
                <div class="main-title">
                    <div class="title">
                        资讯中心
                        <span>资讯中心</span>
                    </div>
                </div>
                <div class="grid-box">
                    <Tabs :tabs="tabs.announcement || []" :more="'Project'" />
                    <Tabs :tabs="tabs.news || []" :more="'News'"/>
                </div>
            </div>
        </section>
        <section class="qualification">
            <div class="content-container qualification-container">
                <div class="main-title">
                    <div class="title">
                        荣誉展示
                        <span>荣誉展示</span>
                    </div>
                </div>
                <Carousel :honor="honor" />
            </div>
        </section>
        <section class="feedback">
            <div class="content-container feedback-container">
                <div class="main-title">
                    <div class="title">
                        留言反馈
                        <span>留言反馈</span>
                    </div>
                </div>
                <p class="tip">我们十分注重您的留言反馈，若您有任何需求或疑问，请反馈给我们，谢谢。</p>
                <el-form :model="feedBack" ref="feedback" :rules="rules" class="my-form">
                    <el-form-item prop="username">
                        <el-input v-model="feedBack.username" placeholder="您的姓名"></el-input>
                    </el-form-item>
                    <el-form-item prop="mobile">
                        <el-input maxlength="11" v-model.number="feedBack.mobile" placeholder="您的联系方式"></el-input>
                    </el-form-item>
                    <el-form-item prop="content">
                        <el-input v-model="feedBack.content" type="textarea" placeholder="请输入您需要反馈的内容"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="my-button" type="primary" @click="preSubmit('feedback')">
                            <img class="icon" src="@/assets/image/icon/send.png" alt="icon" />
                            发送
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </section>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    toRefs
} from 'vue'
import {useRouter} from 'vue-router'
import Tabs from '@/components/Tabs'
import Carousel from '@/components/Carousel'
export default {
    name: 'home',
    components: {
        Tabs,
        Carousel
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const checkMobile = (rule, value, callback) => {
            if (!value.toString().match(/^1[0-9]{10}$/)) {
                callback(new Error("请输入有效的手机号码"));
            }
            callback();
        }
        const state = reactive({
            biddingData: [
                {position: '福建省-福州市', name: '中国人民大学东南区立德教师公寓家具购置', issueTime: '2021-08-20', status: '2', endTime: '2021-08-20'},
                {position: '福建省-福州市', name: '中国人民大学东南区立德教师公寓家具购置脑壳给弄啊士大', issueTime: '2021-08-20', status: '2', endTime: '2021-08-20'},
                {position: '福建省-福州市', name: '中国人民大学东南区立德教师公寓家具购置', issueTime: '2021-08-20', status: '2', endTime: '2021-08-20'},
                {position: '福建省-福州市', name: '中国人民大学东南区立德教师公寓家具购置', issueTime: '2021-08-20', status: '2', endTime: '2021-08-20'},
                {position: '福建省-福州市', name: '中国人民大学东南区立德教师公寓家具购置', issueTime: '2021-08-20', status: '2', endTime: '2021-08-20'},
            ],
            feedBack: {
                username: '',
                mobile: '',
                content: ''
            },
            rules: {
                username: [
                    {required: true, message: "姓名不能为空", trigger: "blur"}
                ],
                mobile: [
                    {required: true, message: '手机号码不能为空', trigger: 'blur'},
                    {type: 'number', message: '手机号码必须为数字', trigger: 'blur'},
                    {validator: checkMobile, message: '手机号码不正确', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: "内容不能为空", trigger: "blur"}
                ]
            },
            tabs: {},
            honor: []
        })

        proxy.$api.index.getHomeData().then(res => {
            state.tabs = res.types || {}
            state.honor = res.honor && res.honor.length ? res.honor : []
        })
        
        

        const preSubmit = (formName) => {
            proxy.$refs[formName].validate((valid) => {
                if(valid) {
                    submit()
                } else {
                    return false
                }
            })
        }
        const submit = () => {
            proxy.$api.feedback.sendFeedback(state.feedBack).then(res => {
                proxy.$message({
                    message: res.msg,
                    type: 'success'
                })
            }).finally(() => {
                feedBackReset()
            })
            
        }
        const feedBackReset = () => {
            Object.keys(state.feedBack).forEach(key => state.feedBack[key] = '')
        }
        const router = useRouter()
        const goIntro = () => {
            router.push({path: '/intro'})
        }

        return {
            ...toRefs(state),
            preSubmit,
            goIntro
        }
    },
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>