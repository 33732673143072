<template>
    <div class="container">
        <router-link class="link" :to="{name: more}">查看更多</router-link>
        <el-tabs v-model="activeName" v-if="!!tabs" class="my-tabs">
            <el-tab-pane
            v-for="(item) in tabs"
            :key="item.id"
            :label="item.name"
            :name="item.id.toString()">
            <p class="piece" v-for="piece in list" :key="piece.id" @click="goDetail(piece.id)">
                <span class="title">{{piece.title}}</span>
                <span class="date">{{piece.publish_at}}</span>
            </p>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    toRefs,
    watch,
} from 'vue'
import { useRouter } from 'vue-router'
export default {
    name: 'tabs',
    props: {
        tabs: {
            type: Array,
            required: true
        },
        more: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { proxy } = getCurrentInstance()
        const router = useRouter()
        const state = reactive({
            activeName: '',
            tabs: props.tabs,
            more: props.more,
            list: []
        })

        watch(() => props.tabs, (newVal) => {
            state.activeName = newVal && newVal.length ? newVal[0].id.toString() : ''
        })

        watch(() => state.activeName, (newVal) => {
            proxy.$api.article.getList(newVal).then(res => {
                state.list = res.data ? res.data : []
            })
        })

        const goDetail = (id) => {
            // proxy.$api.article.getArticle(id)
            if(state.more == 'News') router.push({ path: '/newsCenter/newsDetail', query: { id }})
            if(state.more == 'Project') router.push({ path: '/projectCenter/projectDetail', query: { id }})
                
        }

        return {
            ...toRefs(state),
            goDetail
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>